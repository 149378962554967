import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Root,
  Title,
  Container,
  Item,
  IconContainer,
  ItemTitle,
  DescContainer,
} from "./AdvantagesGrid.style";

import { Typography } from "../../../ui/Typography/Typography";

export function AdvantagesGrid() {
  return (
    <Root>
      <Title>
        <Typography type="h2">
          <FormattedMessage defaultMessage="Discover Club H’s enhanced benefits and features" />
        </Typography>
      </Title>

      <Container>
        <Item>
          <IconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M36.859 18.48a.62.62 0 0 0-.044-.261l-.007-.012a.59.59 0 0 0-.081-.134l-6.047-7.4c-.005-.007-.015-.009-.021-.016a.598.598 0 0 0-.188-.143c-.007 0-.013-.01-.02-.013a.603.603 0 0 0-.244-.051H9.959a.604.604 0 0 0-.244.052c-.006 0-.011.009-.017.012a.597.597 0 0 0-.19.145c-.006.007-.015.009-.021.016l-6.047 7.4a.587.587 0 0 0-.08.134l-.007.012a.598.598 0 0 0-.044.2v.061a.594.594 0 0 0 .027.156c.005.018.01.036.017.054 0 .01 0 .019.009.029a.61.61 0 0 0 .097.153v.007l16.184 16.913c.009.01.023.013.032.023.04.036.084.067.132.091a.553.553 0 0 0 .552 0 .597.597 0 0 0 .132-.091c.01-.009.024-.013.033-.023l16.172-16.917v-.007a.598.598 0 0 0 .106-.153c0-.009 0-.019.009-.029a.552.552 0 0 0 .016-.054.58.58 0 0 0 .027-.155l.005.001Zm-12.218.588-4.561 14.3-4.558-14.3h9.119Zm-8.753-1.218 4.192-5.754 4.194 5.754h-8.386Zm9.553-.469-4.162-5.71h7.818l-3.656 5.71Zm-10.715 0-3.653-5.71h7.812l-4.159 5.71Zm-.477 1.687 4.456 13.982L5.333 19.067l8.916.001Zm11.672 0h8.911L21.459 33.049l4.462-13.981Zm.67-1.218 3.684-5.753 4.7 5.753h-8.384Zm-16.7-5.753 3.683 5.753H5.193l4.698-5.753Zm23.863-6.411V4.103a.608.608 0 1 1 1.218 0v1.583a.609.609 0 0 1-1.218 0Zm0 4.262V8.361a.608.608 0 1 1 1.218 0v1.583a.609.609 0 0 1-1.218 0v.004Zm3.835-2.994a.61.61 0 0 1-.609.609h-.974a.609.609 0 0 1 0-1.218h.974a.608.608 0 0 1 .61.609h-.001Zm-6.453 0a.61.61 0 0 1 .609-.609h.974a.608.608 0 1 1 0 1.218h-.974a.61.61 0 0 1-.611-.609h.002Zm-4.411-4.21V1.77a.608.608 0 1 1 1.218 0v.974a.609.609 0 0 1-1.218 0Zm0 3.653v-.974a.608.608 0 1 1 1.218 0v.974a.609.609 0 0 1-1.218 0Zm1.644-2.385a.61.61 0 0 1 .609-.609h.368a.608.608 0 1 1 0 1.218h-.368a.608.608 0 0 1-.61-.606l.001-.003Zm-3.653 0a.61.61 0 0 1 .609-.609h.368a.608.608 0 1 1 0 1.218h-.368a.608.608 0 0 1-.61-.606l.001-.003Z"
              />
            </svg>
          </IconContainer>
          <ItemTitle>
            <Typography type="h3">
              <FormattedMessage defaultMessage="Outstanding Brands" />
            </Typography>
          </ItemTitle>
          <DescContainer>
            <Typography type="text-primary">
              <FormattedMessage defaultMessage="Maximize your rewards by earning points across 9 top professional beauty brands" />
            </Typography>
          </DescContainer>
        </Item>
        <Item>
          <IconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M35.096 12.166a.634.634 0 0 0-.059-.244c-.008-.016-.01-.028-.017-.042l-3.49-6.108a.582.582 0 0 0-.508-.292H8.979a.58.58 0 0 0-.508.292l-3.49 6.106c-.007.013-.011.028-.017.041l-.019.049a.6.6 0 0 0-.04.196v2.901a4.202 4.202 0 0 0 1.473 3.193v15.679c0 .322.262.584.585.584h26.075a.584.584 0 0 0 .584-.584V18.266a4.2 4.2 0 0 0 1.474-3.193v-2.907Zm-1.59-.579H27.69l-1.485-4.94h4.484l2.817 4.94Zm-14.09-4.94v4.94H13.53l1.486-4.939 4.4-.001Zm5.574 0 1.485 4.94h-5.894v-4.94h4.409Zm-11.656 6.108h6.088v2.312c0 2.344-2.536 3.808-4.566 2.636a3.043 3.043 0 0 1-1.522-2.636v-2.312Zm7.256 0h6.088v2.312c0 2.344-2.536 3.808-4.566 2.636a3.043 3.043 0 0 1-1.522-2.636v-2.312ZM9.312 6.647h4.484l-1.486 4.94H6.494l2.818-4.94Zm-3.239 8.42v-2.312h6.087v2.312c0 2.344-2.536 3.808-4.566 2.636a3.043 3.043 0 0 1-1.522-2.636h.001Zm20.285 18.287H13.643v-9.197h12.715v9.197Zm6.096 0h-4.928v-9.781a.584.584 0 0 0-.584-.584H13.059a.584.584 0 0 0-.584.584v9.78H7.547v-14.38a4.192 4.192 0 0 0 5.199-1.777c1.623 2.775 5.631 2.775 7.254 0 1.621 2.778 5.634 2.778 7.256 0a4.193 4.193 0 0 0 5.201 1.776l-.003 14.382Zm-1.573-15.243a3.045 3.045 0 0 1-3.041-3.044v-2.312h6.088v2.312a3.05 3.05 0 0 1-3.047 3.044Z"
              />
            </svg>
          </IconContainer>
          <ItemTitle>
            <Typography type="h3">
              <FormattedMessage defaultMessage="Various Distributors" />
            </Typography>
          </ItemTitle>
          <DescContainer>
            <Typography type="text-primary">
              <FormattedMessage defaultMessage="Earn and combine points from participating distributor houses or through Schwarzkopf Professional Direct Sales" />
            </Typography>
          </DescContainer>
        </Item>
        <Item>
          <IconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M34.122 11.825h-9.938a8.257 8.257 0 0 0 4.44-3.274 2.783 2.783 0 0 0 .382-2.863 1.919 1.919 0 0 0-1.957-.816c-3.13.254-5.899 3.86-7.04 5.564-1.144-1.704-3.91-5.315-7.04-5.564a1.923 1.923 0 0 0-1.957.816 2.78 2.78 0 0 0 .382 2.863 8.255 8.255 0 0 0 4.431 3.274H5.884a.644.644 0 0 0-.636.646v5.619a.644.644 0 0 0 .636.646h.547v15.78a.644.644 0 0 0 .635.646h25.869a.645.645 0 0 0 .636-.646V18.74h.544a.645.645 0 0 0 .636-.646v-5.619a.65.65 0 0 0-.629-.65Zm-12.029 1.292v4.324h-4.174v-4.324h4.174Zm11.388 4.324H23.376v-4.324h10.102l.003 4.324ZM27.145 6.16c.044 0 .1-.008.163-.008.209 0 .494.035.597.206.134.224.077.791-.369 1.507a7.643 7.643 0 0 1-6.382 3.17c1.083-1.585 3.499-4.678 5.991-4.875ZM12.473 7.87c-.448-.716-.508-1.283-.368-1.507.104-.171.381-.206.596-.206.064 0 .12 0 .163.008 2.492.197 4.908 3.29 5.992 4.877a7.653 7.653 0 0 1-6.383-3.172Zm-5.942 5.25h10.103v4.323H6.531V13.12Zm1.188 5.794h8.919V33.87H7.72l-.001-14.956Zm10.202 14.959v-14.96h4.175V33.87l-4.175.003Zm14.369 0h-8.915v-14.96h8.919l-.004 14.96Z"
              />
            </svg>
          </IconContainer>
          <ItemTitle>
            <Typography type="h3">
              <FormattedMessage defaultMessage="exclusive Rewards" />
            </Typography>
          </ItemTitle>
          <DescContainer>
            <Typography type="text-primary">
              <FormattedMessage defaultMessage="Reward yourself with free backbar essentials, exclusive rewards and access to educational seminars" />
            </Typography>
          </DescContainer>
        </Item>
        <Item>
          <IconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M32.2 26.481c-2.354-1.591-2.277-3.802-2.182-6.603a16.792 16.792 0 0 0-.469-5.63c-1.36-4.424-4.652-7.148-8.878-7.409V5.59a.668.668 0 1 0-1.339 0v1.249c-4.227.261-7.512 2.985-8.877 7.409a16.818 16.818 0 0 0-.469 5.63c.097 2.801.172 5.011-2.182 6.603a2.9 2.9 0 0 0-1.229 3.281 2.388 2.388 0 0 0 2.217 1.784h7.615c.049 2.765 3.074 4.44 5.444 3.015a3.592 3.592 0 0 0 1.74-3.015h7.616a2.386 2.386 0 0 0 2.217-1.784 2.898 2.898 0 0 0-1.229-3.281h.005Zm-12.197 7.255c-1.256 0-2.035-.771-2.243-2.188h4.486c-.208 1.416-.987 2.188-2.243 2.188Zm12.144-4.364a1.076 1.076 0 0 1-.937.837H8.79a1.078 1.078 0 0 1-.938-.837 1.543 1.543 0 0 1 .694-1.782c2.969-2.005 2.868-4.929 2.773-7.758a15.572 15.572 0 0 1 .414-5.192c1.271-4.123 4.276-6.487 8.265-6.487 3.99 0 6.999 2.364 8.266 6.487.43 1.695.571 3.451.416 5.192-.098 2.828-.201 5.752 2.773 7.758a1.54 1.54 0 0 1 .694 1.782Z"
              />
            </svg>
          </IconContainer>
          <ItemTitle>
            <Typography type="h3">
              <FormattedMessage defaultMessage="promotions" />
            </Typography>
          </ItemTitle>
          <DescContainer>
            <Typography type="text-primary">
              <FormattedMessage defaultMessage="Snag member-only promotions tailored just for you" />
            </Typography>
          </DescContainer>
        </Item>
        <Item>
          <IconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M20.135 11.695c-4.316-.038-7.054 4.613-4.927 8.37 2.127 3.755 7.523 3.801 9.712.08a5.604 5.604 0 0 0 .775-2.843 5.583 5.583 0 0 0-5.56-5.607Zm0 9.999c-3.38.027-5.523-3.616-3.857-6.556 1.665-2.942 5.891-2.977 7.606-.064.397.676.606 1.444.606 2.228a4.374 4.374 0 0 1-4.355 4.392Zm0-16.076C9.063 5.522 2.04 17.448 7.493 27.083c5.453 9.637 19.292 9.756 24.911.217a14.395 14.395 0 0 0 1.989-7.299c.035-7.909-6.349-14.349-14.258-14.383Zm-6.869 25.567v-1.531a3.638 3.638 0 0 1 3.616-3.65h6.501a3.64 3.64 0 0 1 3.619 3.65v1.531a12.926 12.926 0 0 1-13.738 0h.002Zm14.94-.851v-.68a4.852 4.852 0 0 0-4.825-4.866h-6.499a4.854 4.854 0 0 0-4.825 4.866v.68a13.238 13.238 0 0 1-4.259-14.655c3.201-9.495 15.481-11.962 22.103-4.442a13.007 13.007 0 0 1 2.565 4.442 13.235 13.235 0 0 1-4.26 14.655Z"
              />
            </svg>
          </IconContainer>
          <ItemTitle>
            <Typography type="h3">
              <FormattedMessage defaultMessage="Unique recommendations" />
            </Typography>
          </ItemTitle>
          <DescContainer>
            <Typography type="text-primary">
              <FormattedMessage defaultMessage="Make the most of personalized product recommendations tailored to your unique business needs" />
            </Typography>
          </DescContainer>
        </Item>
        <Item>
          <IconContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M34.833 10.875h-9.201l-1.403-3.084a.569.569 0 0 0-.519-.339h-7.417a.569.569 0 0 0-.519.339l-1.404 3.088h-2.984V9.164a.564.564 0 0 0-.564-.566H7.453a.566.566 0 0 0-.566.566v1.711H5.166a.567.567 0 0 0-.566.565v20.541c0 .312.254.566.566.566h29.667a.567.567 0 0 0 .566-.566V11.445a.567.567 0 0 0-.566-.57ZM16.661 8.593h6.682l1.037 2.282h-8.756l1.037-2.282ZM8.023 9.734h2.225v1.141H8.023V9.734Zm26.242 2.281v4.843h-7.917c-3.114-4.346-9.579-4.346-12.692 0H5.738v-4.843h28.527Zm-14.263 2.724c5.149.002 8.365 5.578 5.789 10.036-2.575 4.459-9.013 4.457-11.587-.003a6.69 6.69 0 0 1 5.798-10.033ZM5.741 31.411V18h7.23c-2.659 5.411 1.538 11.67 7.553 11.266C26.081 28.893 29.484 23 27.028 18h7.233v13.411H5.741Zm11.503-9.659a.566.566 0 0 1 0-.806l.98-.981a.571.571 0 1 1 .809.806l-.98.981a.566.566 0 0 1-.808 0h-.001Zm2.852 1.141a.569.569 0 0 1 0-.807l1.858-1.852a.57.57 0 0 1 .953.256.57.57 0 0 1-.146.551l-1.859 1.858a.565.565 0 0 1-.807 0l.001-.006Z"
              />
            </svg>
          </IconContainer>
          <ItemTitle>
            <Typography type="h3">
              <FormattedMessage defaultMessage="original content" />
            </Typography>
          </ItemTitle>
          <DescContainer>
            <Typography type="text-primary">
              <FormattedMessage defaultMessage="Keep your finger on the pulse with exclusive Club H events, contests and news" />
            </Typography>
          </DescContainer>
        </Item>
      </Container>
    </Root>
  );
}
